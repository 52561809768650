<style>
  div {
    text-align: center;
  }
  a {
    font-size: 0;
  }
</style>

<div>
  <a href='/games'>Games</a>
  <a href='/achievements'>Achievements</a>
  <a href='/settings'>Settings</a>
  <a href='/highscore'>High Score</a>
  <a href='/about'>About</a>
  <a href='/credits'>Credits</a>
  <a href='/credits'>Credits</a>
  <a href='/games/[game]'>A Game</a>
  <a href='/players/[player]'>A Player</a>
</div>
