<script>
  import Tile from './Tile.svelte';
</script>

<style>
  div {
    width: 390px;
    margin: 0 auto 15px auto;
  }

  :global(#logo .tile) {
    width: 60px;
    height: 60px;
    font-size: larger;
  }

  :global(#logo .tile:last-child) {
    transform: rotate(-15deg);
    margin-left: 5px;
  }

</style>

<div id='logo'>
  <Tile tile={{name:'Al'}} />
  <Tile tile={{name:'C'}} />
  <Tile tile={{name:'He'}} />
  <Tile tile={{name:'Mo'}} />
  <Tile tile={{name:'U'}} />
  <Tile tile={{name:'S'}} />
</div>
