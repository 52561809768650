<script>
  import { createEventDispatcher } from 'svelte';
  import { player } from '../helpers/player';

  const dispatch = createEventDispatcher();
  let API_USERNAME = '';
  let API_PASSWORD = '';

  function error(message) {
     dispatch('error', { message });
  }

  function success() {
    dispatch('success');
  }

  function validateCredentials() {
    if (!API_USERNAME) {
      error('Please provide a username');
    } else if (!API_PASSWORD) {
      error('Please provide a password');
    }
    return API_USERNAME && API_PASSWORD;
  }

  async function login() {
    if (validateCredentials()) {
      await player.login(API_USERNAME, API_PASSWORD)
        .then(success)
        .catch(({ message }) => error(message));
    }
  }

  async function create() {
    if (validateCredentials()) {
      await player.createAccount(API_USERNAME, API_PASSWORD)
        .then(login)
        .catch(({ message }) => error(message));
    }
  }
</script>

<style>
	form {
		width: 400px;
		margin: auto;
	}
</style>

<form id='loginForm' on:submit|preventDefault={login}>
  <input type='text' placeholder='Username' bind:value={API_USERNAME} />
  <input type='password' placeholder='Password' bind:value={API_PASSWORD} />
  <button on:click|preventDefault={login}>Log In</button>
  <button on:click|preventDefault={create}>Create Account</button>
</form>
